<template>
  <div>
    <b-row class="m-0">
      <b-col
        class="content-header"
        cols="12"
      >
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ $route.meta.pageTitle }} <span v-show="archive" class="text-danger">&nbsp;(archive)</span>
        </h2>
        <div class="content-header-search mr-1">
          <b-form-input
            id="searchUser"
            v-model="searchUser"
            placeholder="Rechercher un utilisateur"
            v-on:input="onFilterTextBoxChanged()"
            class="w-full"
          />
        </div>
        <div class="content-header-actions">
          <b-button
            variant="outline-primary"
            class="px-1 mr-1"
            v-show="rowSelected.length>0"
            @click="activeUsersLocal()"
          > 
            <feather-icon icon="SendIcon" />
            {{rowSelected.length>1 ? 'Activer les comptes' : 'Activer le compte'}}
          </b-button>
          <b-button
            variant="primary"
            @click="newUser('')"
            class="d-flex px-1 mr-1"
          > 
            <feather-icon icon="PlusIcon" />
            <span class="d-none d-md-block">Nouveau</span> 
          </b-button>
          <b-dropdown class="bg-outline-primary btn-group-icon" variant="outline-primary">
            <template #button-content>
              <span class="material-icons-outlined m-0 p-0">settings</span>
            </template>
            <b-dropdown-item class="w-full border-bottom-grey-light" @click="archive=!archive">{{ !archive ? 'Voir les utilisateurs archivés' : 'Voir les utilisateurs actifs' }}</b-dropdown-item>
            <b-dropdown-item class="w-full border-bottom-grey-light" @click="togglePanelColumn">{{this.gridOptions.sideBar == null? 'Gérer les colonnes' : 'Masquer la gestion des colonnes' }}</b-dropdown-item>
            <b-dropdown-item class="w-full" @click="exportDataCSV">Exporter en CSV</b-dropdown-item>
            <b-dropdown-item class="w-full" @click="exportDataXLS">Exporter en XLS</b-dropdown-item>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <div class="content-grid">
      <div class="text-center flex-center" v-if="isLoadingUsersList || isLoadingUsersListArchived">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          {{archive ? 'Chargement des archives en cours...' : 'Chargement de la liste des utilisateurs...'}}
        </div>         
      </div>
      <ag-grid-vue v-else style="width: 100%; height: 100%;"
          class="ag-theme-material"
          :class="archive ? 'ag-archived' : ''"
          :rowData="gridData"
          :gridOptions="gridOptions"
          :key="archive+filter"
          @grid-ready="onGridReady"
          @rowClicked="onRowClicked"
          @selection-changed="onSelectionChanged">
      </ag-grid-vue>
    </div>
    <sidebar-user ref="sidebarUser"/>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue"
import { agGridLocaleFR } from "@/data/agGridLocaleFR"

import {
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions } from 'vuex'


import SidebarUser from '@/components/directory/user/SidebarUser'


export default {
  components: {
    BButton,
    AgGridVue,
    SidebarUser
  },
  directives: {
    Ripple
  },
  data () {
    return {
      archive:false,
      filter: false,
      sidebar:false,
      activeSidebarUser:false,
      gridOptions:{
        localeText: agGridLocaleFR,
        columnDefs: null,
        defaultColDef: {
          flex: 1,
          minWidth: 100,
          editable: false,
        },
        enableRangeSelection: true,
        pagination:true,
        paginationPageSize:20,
        rowSelection: null,
      },
      gridApi: null,
      columnApi: null,
      searchUser: '',
      rowSelected:[],
      rowSelectedIds:[]
    }
  },
  computed: {
    ...mapGetters([
      'usersList',
      'usersListArchived',
      'isLoadingUsersList',
      'isLoadingUsersListArchived',
      'isOpenSidebarUser',
    ]),
    gridData () {
      if(this.archive){
        return this.$store.getters.usersListArchived
      }else{
        return this.$store.getters.usersList
      }
    }
  },
  created(){
    this.gridOptions.rowSelection = 'multiple';
    this.fetchUsersList();
    this.fetchUsersListArchived();
  },
  beforeMount() {
    this.gridOptions.columnDefs = [
      {
          field: 'selection',
          headerName: '',
          maxWidth: 40,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
      },
      { field: 'isActive', headerName: 'Statut', resizable:true, lockVisible:false, sortable: true, headerClass: 'ag-header-cell-center', cellStyle: {textAlign: 'center'}, maxWidth: 120, cellRenderer: 
        params => { return params.value ? 'Actif' : 'Inactif'  }
      },
      { field: 'civility', headerName: 'Civilité', lockVisible:true, maxWidth: 120, sortable: true, headerClass: 'ag-header-cell-center', cellStyle: {textAlign: 'center'}, cellRenderer: 
        params => { return params.value == 0 ? 'M.' : params.value == 1 ? 'Mme.' : 'N.C'  }
      },
      
      { field: 'lastName', headerName: 'Nom', resizable:true, lockVisible:true, sortable: true, minWidth: 160},
      { field: 'firstName', headerName: 'Prénom', resizable:true, lockVisible:true, sortable: true, minWidth: 160},
      { field: 'email', headerName: 'Email', resizable:true, wrapText:true, lockVisible:true, sortable: true, minWidth: 260 },
      { field: 'phoneNumber', headerName: 'Téléphone', resizable:true, initialHide:true, sortable: true, minWidth: 118 },
      
    ];
  },
  methods: {
    ...mapActions([ 
      'fetchUsersList',
      'fetchUsersListArchived',
      'deleteUser',
      'activeUser'
    ]),

    // Gestion d'AgGrid
    autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.gridApi.getColumnDefs().forEach((column) => {
        allColumnIds.push(column.field);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setHeaderHeight(36);
      this.$nextTick(() => {
        //this.autoSizeAll(true)
      })
    },
    onRowClicked(event){
      if(this.archive){
        this.$refs['sidebarUser'].getUserArchivedDetails(event.data.id, 1)
      }else{
        this.$refs['sidebarUser'].getUserDetails(event.data.id)
      }
      this.$store.commit('SET_IS_OPEN_SIDEBAR_USER', true)
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(
        this.searchUser
      );
    },
    onSelectionChanged(event) {
      this.rowSelected = event.api.getSelectedNodes();
      if(this.rowSelected.length>0){
        this.rowSelectedIds = this.rowSelected.map(elem => { return elem.id })
      }
    },
    togglePanelColumn(){
      if(!this.gridOptions.sideBar || this.gridOptions.sideBar== null ){
        this.gridOptions.sideBar = {
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Colonnes',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
              },
            },
          ],
          defaultToolPanel: 'columns',
        }
        this.filter=true;
      }else{
        this.gridOptions.sideBar= null
        this.filter=false;
      }
    },
    exportDataXLS(){
      this.gridApi.exportDataAsExcel();
    },
    exportDataCSV(){
      this.gridApi.exportDataAsCsv();
    },
    activeUsersLocal () {
      let messageTitle = 'Êtes-vous sûr de vouloir activer cet utilisateur ?'
      let messageSubTitle = 'En activant cet utilisateur, il recevra un email pour initialiser son mot de passe.'
      
      if(this.rowSelected.length>1){
        messageTitle = 'Êtes-vous sûr de vouloir activer ces utilisateurs ?'
        messageSubTitle = 'En activant ces utilisateurs, ils recevront un email pour initialiser leurs mots de passe.'
      }

      this.$bvModal
        .msgBoxConfirm(messageSubTitle, {
          title: messageTitle,
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Activer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.activeUser({userIds:this.rowSelectedIds})
            this.$store.commit('SET_IS_OPEN_SIDEBAR_USER', false)
          }
        })
    },

    // Sidebar user
    newUser () { 
      this.$router.push({ path: '/new-user' })
    },
  }
}
</script>
