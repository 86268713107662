<template>
    <vs-sidebar
        position-right
        v-model="active"
        parent="body" default-index="55555551"
        class="items-no-padding"
        >
        <div v-if="isLoadingUser || isUpdatingUser || isDeletingUser" class="h-100">
            <div class="text-center flex-center h-100">
              <div class="loading-bg-inner">
                <div class="loader">
                  <div class="outer"></div>
                  <div class="middle"></div>
                  <div class="inner"></div>
                </div>
              </div>
              <div class="mt-5">
                <br /><br /><br />
                Chargement des détails de l'utilisateur...
              </div>
            </div>
        </div> 

        <div v-else-if="user" class="h-full">
            <div class="sidebar-header py-1 px-2">
                <div class="d-flex align-items-center">
                  <div>
                    <h3>{{user.firstName+' '+user.lastName }}</h3>
                  </div>
                </div>
                <feather-icon icon="XIcon" size="24" @click.stop="active =!active" class="close-icon cursor-pointer"></feather-icon>
            </div>

            <vs-divider class="m-0" />

            <div class="sidebar-action py-05 px-2">
                <div class="d-flex align-items-center">

                    <b-button
                      v-if="archived"
                      variant="warning"
                      class="btn-icon"
                      @click="restoreUserLocal(user.id, user.firstName+' '+user.lastName)"
                    >
                        <feather-icon icon="ArrowDownIcon" /> Restaurer
                    </b-button>
                    <b-button
                      v-else
                      class="btn-icon"
                      variant="primary"
                      @click="editUser(user.id)"
                      :disabled="archived"
                    >
                        <feather-icon icon="EditIcon" /> Modifier
                    </b-button>
                    <b-button v-if="!user.isActive && !archived"
                      variant="outline-primary"
                      class="btn-icon ml-1"
                      @click="activeUserLocal(user.id, user.firstName+' '+user.lastName)"
                    >
                        <feather-icon icon="SendIcon" />  Activer le compte
                    </b-button>
                </div>
                <b-button-group v-if="archived">
                    <b-button
                      variant="outline-danger"
                      class="btn-icon"
                      @click="deleteUserLocal(user.id, user.firstName+' '+user.lastName)"
                    >
                        <feather-icon icon="TrashIcon" />  Supprimer définitivement
                    </b-button>
                </b-button-group>
                <b-button-group v-else>
                    
                    <b-button
                      variant="outline-danger"
                      class="btn-icon"
                      @click="archiveUserLocal(user.id, user.firstName+' '+user.lastName)"
                    >
                        <feather-icon icon="ArchiveIcon" /> 
                    </b-button>
                </b-button-group>
            </div>

            <vs-divider class="m-0" />

            <div class="sidebar-content">
                <div v-show="user.secondaryPhoneNumber || user.phoneNumber || user.email">
                    <h4 class="mb-1 text-primary"><feather-icon icon="PhoneCallIcon" /> Coordonnées</h4>
                    <ul class="listTab">
                        <li v-show="user.email">
                            <span>Adresse e-mail</span>
                            <span>{{user.email}}</span>
                        </li>
                        <li v-show="user.phoneNumber">
                            <span>Téléphone principal</span>
                            <span>{{user.phoneNumber}}</span>
                        </li>
                    </ul>
                    <vs-divider /> 
                </div>
            </div>
        </div>
    </vs-sidebar>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      archived:false,
      user:{},
    }
  },
  computed: {
    ...mapGetters([
      "isUpdatingUser",
      "compagniesList",
      "isLoadingUser",
      "isDeletingUser",
      "isOpenSidebarUser",
    ]),
    active: {
      get ()    { return this.isOpenSidebarUser },
      set (val) { this.$store.commit('SET_IS_OPEN_SIDEBAR_USER', val) }
    },
  },
  methods: {
    ...mapActions([ "updateUser", "deleteUser", "isArchivingUser", "archiveUser", "activeUser", "restoreUser", "fetchUser", "fetchUserArchived"]),
    getUserArchivedDetails(id, archived) {
      this.archived=archived
      if (id>0) {
        this.fetchUserArchived(id).then(res => {
          this.user = res
        })
      } else {
        this.user={}
      }
    },
    getUserDetails(id) {
      this.archived=false
      if (id>0) {
        this.fetchUser(id).then(res => {
          this.user = res
        })
      } else {
        this.user={}
      }
    },
    async editUser(id){
        this.$store.commit('SET_IS_OPEN_SIDEBAR_USER', false) 
        this.$router.push({ name: 'edit-user', params: { id: id, title: "Edit : "+ this.user.firstName + ' ' + this.user.lastName, tips:"Editer le user : "+this.user.firstName + ' ' + this.user.lastName } })
    },
    activeUserLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('En activant cet utilisateur, il recevra un email pour initialiser son mot de passe.', {
          title:
            'Êtes-vous sûr de vouloir activer "' + label + '" ?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Activer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.activeUser({userIds:[id]})
            this.$store.commit('SET_IS_OPEN_SIDEBAR_USER', false)
          }
        })
    },
    archiveUserLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('En archivant cet utilisateur, il ne pourra plus se connecter. Vous pourrez à tout moment restaurer cet utilisateur en consultant la liste de vos archives.', {
          title:
            'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Archiver',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.archiveUser(id);
            this.$store.commit('SET_IS_OPEN_SIDEBAR_USER', false)
          }
        })
    },
    restoreUserLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Cet utilisateur pourra se reconnecter avec ses identifiants habituels.', {
          title:
            'Êtes-vous sûr de vouloir restaurer "' + label + '" ?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Restaurer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.restoreUser(id);
            this.$store.commit('SET_IS_OPEN_SIDEBAR_USER', false)
          }
        })
    },
    deleteUserLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Cette action est définitive et irréversible.', {
          title:
            'Êtes-vous sûr de vouloir supprimer "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Supprimer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.deleteUser(id)
            this.$store.commit('SET_IS_OPEN_SIDEBAR_USER', false)
          }
        })
    },
  },
}
</script>

